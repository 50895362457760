import { PortfolioSmartPosition } from '@sushiswap/graph-client/data-api';
import { AccordionContent, AccordionItem, AccordionTrigger, Currency } from '@sushiswap/ui';
import React, { FC } from 'react';
import { ChainId, ChainKey } from 'sushi/chain';
import { formatUSD } from 'sushi/format';
import { PortfolioInfoRow } from '../PortfolioInfoRow';
interface PortfolioALMPositionsProps {
  positions: PortfolioSmartPosition[];
  href?: string;
}
export const PortfolioALMPositions: FC<PortfolioALMPositionsProps> = ({
  positions
}) => <AccordionItem value="alm" className="!border-0" data-sentry-element="AccordionItem" data-sentry-component="PortfolioALMPositions" data-sentry-source-file="PortfolioALMPositions.tsx">
    <AccordionTrigger className="px-5 underline-offset-2" data-sentry-element="AccordionTrigger" data-sentry-source-file="PortfolioALMPositions.tsx">
      {`Smart Positions (${positions.length})`}
    </AccordionTrigger>
    <AccordionContent className="cursor-default" data-sentry-element="AccordionContent" data-sentry-source-file="PortfolioALMPositions.tsx">
      {positions.map(position => <PortfolioInfoRow key={`${position.chainId}:${position.id}`} chainId={(position.chainId as ChainId)} href={`/${ChainKey[(position.chainId as ChainId)]}/pool/v3/${position.address}/smart/${position.vaultAddress}`} icon={<Currency.IconList iconWidth={24} iconHeight={24}>
              <img className="rounded-full" src={position.token0.logoUrl} alt={position.token0.symbol} />
              <img className="rounded-full" src={position.token1.logoUrl} alt={position.token1.symbol} />
            </Currency.IconList>} leftContent={<React.Fragment>
              <div className="text-sm font-medium overflow-hidden overflow-ellipsis">
                {position.name}
              </div>
              <div className=" text-xs text-muted-foreground overflow-hidden overflow-ellipsis">{`V3-${position.swapFee * 100}%-${position.strategy}`}</div>
            </React.Fragment>} rightContent={<span className="text-sm font-medium overflow-hidden overflow-ellipsis">
              {formatUSD(position.amountUSD)}
            </span>} />)}
    </AccordionContent>
  </AccordionItem>;